import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from "../../environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  constructor() {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const token = localStorage.getItem(this.authLocalStorageToken) ? JSON.parse(localStorage.getItem(this.authLocalStorageToken)).accessToken : null;
      if (token) {
          request = request.clone({
              setHeaders: {
                  authorization: `Bearer ${ token.toString() }`
              }
          });
      }
      return next.handle(request);
  }
}
