import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, of, Subscription} from 'rxjs';
import {catchError, finalize, map, retry, switchMap} from 'rxjs/operators';
import {UserModel} from '../_models/user.model';
import {AuthModel} from '../_models/auth.model';
import {AuthHTTPService} from './auth-http';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {HttpClient} from "@angular/common/http";
import {DefaultResponse} from "../../../models/defaultResponse";

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    // private fields
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

    // public fields
    currentUser$: Observable<UserModel>;
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<UserModel>;
    isLoadingSubject: BehaviorSubject<boolean>;

    loginError: string = '';

    get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserModel) {
        this.currentUserSubject.next(user);
    }

    constructor(
        private authHttpService: AuthHTTPService,
        private http: HttpClient,
        private router: Router
    ) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
        const subscr = this.getUserByToken().subscribe();
        this.unsubscribe.push(subscr);
    }

    private loginRequest(email, password): Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>('/api/login', {email: email, password: password});
    }

    // public methods
    login(email: string, password: string): Observable<UserModel> {
        this.isLoadingSubject.next(true);
        return this.loginRequest(email, password).pipe(
            map((defaultResponse: DefaultResponse) => {
                if (!defaultResponse.success) {
                    this.loginError = defaultResponse.message + '  Error: ' + defaultResponse.data.error.toString();
                    throw new Error(defaultResponse.message);
                }
                this.loginError = '';
                const auth: AuthModel = new AuthModel(defaultResponse.data.token, null, null);
                return this.setAuthFromLocalStorage(auth);
            }),
            retry(3),
            switchMap(() => this.getUserByToken()),
            catchError(() => {
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    logout() {
        localStorage.removeItem(this.authLocalStorageToken);
        this.router.navigate(['/auth/login'], {
            queryParams: {},
        });
    }

    getRemoteUserByToken(): Observable<UserModel> {
        return this.http.get<UserModel>('/api/userbytoken', {});
    }

    getUserByToken(): Observable<UserModel> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth || !auth.accessToken) {
            return of(undefined);
        }

        this.isLoadingSubject.next(true);
        return this.getRemoteUserByToken().pipe(
            map((user: UserModel) => {
                if (user) {
                    user.fullname = user.name;
                    this.currentUserSubject = new BehaviorSubject<UserModel>(user);
                } else {
                    this.logout();
                }
                return user;
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    // need create new user then login
    registration(user: UserModel): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.createUser(user).pipe(
            map(() => {
                this.isLoadingSubject.next(false);
            }),
            switchMap(() => this.login(user.email, user.password)),
            catchError((err) => {
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    customRegistration(data: any): Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>('/api/register', data);
    }

    forgotPassword(email: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService
            .forgotPassword(email)
            .pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    // private methods
    private setAuthFromLocalStorage(auth: AuthModel): boolean {
        // store auth accessToken/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
        if (auth && auth.accessToken) {
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
            return true;
        }
        return false;
    }

    private getAuthFromLocalStorage(): AuthModel {
        try {
            const authData = JSON.parse(
                localStorage.getItem(this.authLocalStorageToken)
            );
            return authData;
        } catch (error) {
            return undefined;
        }
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    getUserId(id: string) {
        return this.http.get<DefaultResponse>('/api/getuser/' + id);
    }

    changePassword(data: any) {
        return this.http.post<DefaultResponse>('/api/user/change-password', data);
    }

    hasPermissions(module): boolean {
        switch (module){
            case 'superAdmin': {
                return this.currentUserValue.role_id === 1;
            }
            case 'auditor': {
                return this.currentUserValue.role_id !== 3;
            }
        }
        return false;
    }
}
