<div class="full">
    <div class="container-fluid p-10">
        <div *ngIf="!error && !sended" class="card card-custom gutter-b">
            <div class="card-header">
                <div class="card-title">
                    <h3 class="card-label">Encuesta </h3>
                </div>
            </div>
            <div class="card-body p-12" [formGroup]="surveyForm" *ngIf="show_form">
                <div class="row">
                    <div class="col-12 col-lg-3 ml-auto">
                        <div class="image-input image-input-outline" style="width: 100%;"
                             [class.image-input-empty]="'url(./assets/media/logos/logo-edutecno.png)'">
                            <div class="image-input-wrapper" [style.backgroundImage]="getTempLogoUrl" style="height: 25vh; width: 100%; background-position: center; background-size: contain"></div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-9">
                        <div>
                            <h6 class="col-12 text-left">
                                <strong>
                                   Califique nuestas temáticas según describe la siquiente tabla.<br>
                                </strong>
                            </h6>
                            <h6 class="col-12 text-left">
                                <strong>
                                    Ayudenos a mejorar nuestro servicio, agradecemos su tiempo.
                                </strong>
                            </h6>
                        </div>
                        <div class="mt-4">
                            <table class="col-12 table table-responsive-lg" style="background-color: #edeced; border-radius: 10px;">
                                <thead>
                                <tr>
                                    <td class="text-left mb-4" style="color: darkblue">
                                        {{getTextoTotalDesacuerdo}} 
                                        <span *ngIf="formUpdated">{{getTextoTotalDesacuerdoRange}}</span>
                                    </td>
                                    <td class="text-left mb-4" style="color: darkblue">
                                        {{getTextoDeacuerdo}} 
                                        <span *ngIf="formUpdated">{{getTextoDeacuerdoRange}}</span>
                                    </td>
                                    <td class="text-left mb-4" style="color: darkblue">
                                        {{getTextoTotalAcuerdo}} 
                                        <span *ngIf="formUpdated">{{getTextoTotalAcuerdoRange}}</span>
                                    </td>
                                </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td>
                                        <div class="inline row ml-1 mb-4 mr-3 p-2 d-flex align-items-center">
                                            <div class="col-12 col-md-3">
                                                <mat-icon style="color: #ffd500">star</mat-icon>
                                            </div>
                                            <div class="col-12 col-md-7 ml-auto">
                                                <mat-label>Total:  {{totalMinAgree}}</mat-label>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="inline row ml-1 mb-4 mr-3 p-2 d-flex align-items-center">
                                            <div class="col-12 col-md-3">
                                                <mat-icon class="shadow-1" style="color: #ffd500">star</mat-icon>
                                            </div>
                                            <div class="col-12 col-md-7 ml-auto">
                                                <mat-label>Total:  {{totalAgree}}</mat-label>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="inline row ml-1 mb-4 mr-3 p-2 d-flex align-items-center">
                                            <div class="col-12 col-md-3">
                                                <mat-icon style="color: #ffd500">star</mat-icon>
                                            </div>
                                            <div class="col-12 col-md-7 ml-auto">
                                                <mat-label>Total:  {{totalMaxAgree}}</mat-label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                        <div class="survey mt-4">
                            <div formArrayName="questions" *ngFor="let question of getQuestions.controls; index as i">

                                <div [formGroupName]="i">
                                    <div class="col-12 p-2 mt-2" *ngIf="question.value.type  === 'head' || question.value.type  === 'info'">
                                        <h2 *ngIf="question.value.size  === 'h2'" class="mb-3 font-weight-bold text-dark">
                                            {{question.value.text}}
                                        </h2>
                                        <h4 *ngIf="question.value.size  === 'h4'" class="mb-3 font-weight-bold text-dark">
                                            {{question.value.text}}
                                        </h4>
                                        <h6 *ngIf="question.value.size  === 'h6'" class="mb-3 font-weight-bold text-dark">
                                            {{question.value.text}}
                                        </h6>
                                    </div>

                                    <div class="col-12 p-2 form-group" *ngIf="question.value.type === 'note'">
                                        <div class="col-12">
                                            <label>
                                                {{question.value.question}}
                                                <span *ngIf="question.value.required" class="asterick"> *</span>
                                            </label>
                                        </div>
                                        <div class="col-12">
                                            <textarea class="form-control" placeholder="Respuesta" formControlName="answer"></textarea>
                                        </div>
                                    </div>

                                    <div class="col-12 p-2 form-group" *ngIf="question.value.type === 'text'">
                                        <div class="col-12">
                                            <label>
                                                {{question.value.question}}
                                                <span *ngIf="question.value.required" class="asterick"> *</span>
                                            </label>
                                        </div>
                                        <div class="col-12">
                                            <input
                                                type="text"
                                                class="form-control"
                                                placeholder="Respuesta" formControlName="answer"/>
                                        </div>
                                    </div>

                                    <div class="col-12 mb-2 rating_row row d-flex align-items-center" *ngIf="question.value.type === 'rating'">
                                        <div class="col-12 col-md-9">
                                            <label>
                                                {{question.value.question}}
                                                <span *ngIf="question.value.required" class="asterick"> *</span>
                                            </label>
                                        </div>
                                        <div class="col-12 col-md-3 text-right">
                                            <ngb-rating rate="1" [max]="question.value.rating" formControlName="answer">
                                                <ng-template let-fill="fill" let-index="index">
                                                    <span class="star" [class.filled]="fill === 100" [class.bad]="index <= (question.value.rating/3 - 1)" [class.good]="index >= (question.value.rating - question.value.rating/3)">&#9733;</span>
                                                </ng-template>
                                            </ngb-rating>
                                        </div>
<!--                                        <div class="col-1 pl-8 pt-4 col-1">-->
<!--                                            <mat-icon [matBadge]="question.value.answer || 0" [matBadgeColor]="getBadgeColor(question)" style="color: #ffd500">star</mat-icon>-->
<!--                                        </div>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between border-top pt-10">
                            <div class="ml-auto">
                                <button
                                    (click)="onSubmit()"
                                    class="btn btn-light-success font-weight-bold text-uppercase px-9 py-4"
                                    [disabled]="surveyForm.invalid"
                                    data-wizard-type="action-submit">
                                    Enviar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!--begin::Error-->
        <div *ngIf="error && !sended" class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
             [style]="{'background-image': 'url(\'./assets/media/error/bg1.jpg\')', 'height': '100%'}">
            <!--begin::Content-->
            <h1 class="font-weight-boldest text-dark-75 mt-15 msg">
                Disculpe!
            </h1>
            <p class="font-size-h3 text-muted font-weight-normal">
                {{errorMessage}}
            </p>
            <!--end::Content-->
        </div>

        <div *ngIf="sended" class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
             [style]="{'background-image': 'url(\'./assets/media/error/bg1.jpg\')', 'height': '100%'}">
            <!--begin::Content-->
            <h1 class="font-weight-boldest text-dark-75 mt-15 msg">
                Gracias!
            </h1>
            <p class="font-size-h3 text-muted font-weight-normal">
                {{sendLog}}
            </p>
            <!--end::Content-->
        </div>
    </div>
</div>
