import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {DefaultResponse} from "../../../models/defaultResponse";
import {Thematic} from "../models/thematic";

@Injectable({
  providedIn: 'root'
})
export class SurveysService {
    url:string = "/api/survey";

    constructor(private http: HttpClient) {
    }

    saveSurvey(data): Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(this.url, data);
    }

    delete(id): Observable<any> {
        return this.http.delete(`${this.url}/${id}`);
    }

    deleteItems(ids):Observable<any> {
        return this.http.post(this.url + '/delete-many', {ids: ids});
    }

    activate(ids){
        return this.http.post(this.url + '/enable-many', {ids: ids, date: new Date().toLocaleDateString()});
    }

    deactivate(ids){
        return this.http.post(this.url + '/disable-many', {ids: ids});
    }

    getSurveyById(id: string): Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(`${this.url}/${id}`);
    }

    editSurvey(id, data): Observable<DefaultResponse> {
        return this.http.put<DefaultResponse>(this.url+`/${id}`, data);
    }

    getPublicSurvey(hash: string): Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(this.url + '/get-survey-by-hash', {hash: hash});
    }

    uploadLogo(formData: FormData): Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(this.url + '/upload-survey-logo', formData);
    }

    getLogoById(id: number): Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>(this.url + '/get-survey-logo-by-id/' + id);
    }

    getOtecs(): Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>('/api/otec');
    }

    getHoldings(): Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>('/api/holding/list');
    }

    sendAnswer(req: any): Observable<DefaultResponse> {
        return this.http.post<DefaultResponse>(this.url + '/save-survey-answer', req);
    }

    getCourses(otecId): Observable<DefaultResponse> {
        return this.http.get<DefaultResponse>('/api/courses/list/' + otecId);
    }

    getThematics() {
        return this.http.get<DefaultResponse>('/api/thematic/select');
    }

    getEnterprises() {
        return this.http.get<DefaultResponse>('/api/reports/get-all-empresas');
    }
}
