export class AuthModel {
  accessToken: string;
  refreshToken: string;
  expiresIn: Date;

  constructor(accessToken = null, refreshToken = null, expiresIn = null) {
      this.accessToken = accessToken;
      this.refreshToken = refreshToken;
      this.expiresIn = expiresIn;
  }

  setAuth(auth: any) {
    this.accessToken = auth.accessToken;
    this.refreshToken = auth.refreshToken;
    this.expiresIn = auth.expiresIn;
  }
}
