import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SurveysService} from "../../services/surveys.service";
import {Subscription} from "rxjs";
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Survey} from "../../models/survey";
import {BreakpointObserver} from "@angular/cdk/layout";
import {Question} from "../../models/question";

@Component({
  selector: 'app-public',
  templateUrl: './public-survey.component.html',
  styleUrls: ['./public-survey.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PublicSurveyComponent implements OnInit, OnDestroy{
    private subscriptions: Subscription[] = [];

    surveyForm: FormGroup;

    errorMessage = '';
    error: boolean = false;
    private resp: any;
    sended: boolean;
    private sendError: string;
    sendLog: string;
    show_form: boolean =false
    totalMaxAgree = 0;
    totalAgree = 0;
    totalMinAgree = 0;
    average = 0;
    formUpdated = false;
    getTextoTotalDesacuerdoRange: string;
    getTextoDeacuerdoRange: string;
    getTextoTotalAcuerdoRange: string;
    lasTematicShow=-1;
    constructor(private route: ActivatedRoute,
                private surveyService: SurveysService,
                private formBuilder: FormBuilder,
                private observer: BreakpointObserver,
                private changeDetectorRef: ChangeDetectorRef) {
        this.buildSurveyForm();
        const subscription = this.surveyService.getPublicSurvey(this.route.snapshot.paramMap.get('hash')).subscribe(res => {
            if(res.success){
                this.show_form = true;
                this.fillForm(res.data);
                this.resp = res.data;
            } else {
                this.error = true;
                this.errorMessage = res.message;
                this.refresh();
            }
        });
        this.subscriptions.push(subscription);
    }

    ngOnInit(): void {
    }

    fillForm(data){
        this.buildSurveyForm(data.survey);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sb => sb.unsubscribe());
    }

    refresh() {
        this.changeDetectorRef.detectChanges();
    }

    get getTempLogoUrl(){
        const logo = this.surveyForm.get('survey_logo').value
        return logo ? 'url(/storage/' + logo.file_path + ')' : 'url(./assets/media/logos/logo-edutecno.png)';
    }

    get getQuestions(): FormArray{
        return this.surveyForm.get('questions') as FormArray;
    }

    buildSurveyForm(data?: Survey) {
        this.surveyForm = this.formBuilder.group({
            id: data ? data.id : null,
            name: data ? data.name : null,
            default: data ? data.default : null,
            otec_id: data ? data.otec_id : null,
            otec: data ? data.otec : null,
            holding_id: data ? data.holding_id : null,
            course_id: data ? data.course_id : null,
            questions: this.formBuilder.array([]),
            survey_logo_id: data ? data.survey_logo_id : null,
            survey_logo: data ? data.survey_logo : null,
            active: data ? data.active : null,
            active_date: data ? data.active_date : null,
            maxAgreeText: data ? data.maxAgreeText : 'Muy de acuerdo',
            agreeText: data ? data.agreeText : 'De acuerdo',
            minAgreeText: data ? data.minAgreeText : 'Total desacuerdo',
            ratingValue: data ? data.ratingValue : null,
        });
        if(data){
            let questions = this.surveyForm.get('questions') as FormArray;
            data.questions.forEach((item, index) => {
                questions.push(this.buildQuestionForm(item.type, item))
                if(index === data.questions.length - 1){
                    this.refresh();
                }
            });
            this.subscriptions.push(this.surveyForm.get('questions').valueChanges.subscribe((value: Question[]) => {
                let ratings = value.filter(item => item.type === 'rating');
                this.calculate(ratings);
            }));
            //total desacuerdo
            let textTotalDesacuerdoRange: string = '(1'
            const rating = data.ratingValue;
            const terceraParte = Math.trunc(rating/3)
            if(terceraParte > 1){
                textTotalDesacuerdoRange = textTotalDesacuerdoRange + ' - ' + terceraParte;
            }
            this.getTextoTotalDesacuerdoRange =  textTotalDesacuerdoRange.concat(')');

            //acuerdo
            let textDeacuerdoRange: string = '(' + (terceraParte + 1);
            if((terceraParte + 1) < (rating - terceraParte)){
                textDeacuerdoRange = textDeacuerdoRange  + ' - ' + (rating - terceraParte);
            }
            this.getTextoDeacuerdoRange =  textDeacuerdoRange + ')';

            //total acuerdo
            let textTotalAcuerdoRange: string = '(' + (rating - terceraParte + 1);
            if(rating > (rating - terceraParte + 1)){
                textTotalAcuerdoRange = textTotalAcuerdoRange + ' - ' + rating;
            }
            this.getTextoTotalAcuerdoRange =  textTotalAcuerdoRange + ')';

            this.formUpdated = true;
            this.refresh();
        }
    }

    buildQuestionForm(type, instance?): FormGroup {

        return this.formBuilder.group({
            id: instance.id,
            type: instance.type,
            text: instance.text,
            question: instance.question,
            size: instance.size,
            rating: instance.rating,
            required: instance.rating,
            answer: this.canBeAnswred(instance.type) ? instance.required ? ['', Validators.compose([Validators.required])] : '' : null
        });
    }

    canBeAnswred(type): boolean {
        return type === 'text' || type === 'rating' || type === 'note';
    }

    onSubmit() {
        const data = this.surveyForm.getRawValue();
        data.contact_id = this.resp.contact.id;
        data.info = this.resp.info;
        this.subscriptions.push(this.surveyService.sendAnswer(data).subscribe(resp => {
            if(resp.success){
                this.sended = true;
                this.sendLog = resp.message;
                this.refresh();
            } else {
                this.sendError = resp.message;
                this.refresh();
            }
        }));
    }

    getBadgeColor(question: AbstractControl) {
        if(!question.value.answer) {
            return 'custom-warn';
        }
        if(question.value.answer-1 >= (question.value.rating - question.value.rating/3)){
            return 'custom-accent';
        }
        if(question.value.answer-1 <= (question.value.rating/3 - 1)){
            return 'custom-warn';
        }
        return 'custom-primary';
    }

    get getTextoTotalDesacuerdo() {
        return this.surveyForm.get('minAgreeText').value;
    }

    get getTextoDeacuerdo() {
        return this.surveyForm.get('agreeText').value;
    }

    get getTextoTotalAcuerdo() {
        return this.surveyForm.get('maxAgreeText').value;
    }

    get getMaxRating() {
        return this.surveyForm.get('ratingValue').value;
    }

    get getMiddleScale() {
        const total = this.surveyForm.get('ratingValue').value;
        let scale: string = '';
        for (let i = 2; i < total; i++){
            scale += ' ' + ' ' + i.toString();
        }
        return scale;
    }

    private calculate(ratings: any[]) {
        this.totalMinAgree = 0;
        this.totalAgree = 0;
        this.totalMaxAgree = 0;
        const rating = this.surveyForm.get('ratingValue').value;
        const terceraParte = Math.trunc(rating/3);
        let total = 0;
        ratings.forEach(item => {
            total += item.answer;
            if(item.answer && item.answer <= terceraParte  ) this.totalMinAgree++;
            if(item.answer > rating - terceraParte) this.totalMaxAgree++;
            if(item.answer > terceraParte && item.answer <= (rating-terceraParte)) this.totalAgree++;
        });
        if(ratings.length){
            this.average = Number(parseFloat((total / ratings.length).toString()).toFixed(2));
        }
    }
}
